<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        v-if="orderInfo"
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="订单详情"
        :sub-title="this.$route.query.order_no"
        @back="() => $router.go(-1)"
      >
        <a-divider orientation="left" v-if="false">
          {{ this.$route.query.order_no }}
        </a-divider>
        <a-row :gutter="16" v-if="false">
          <a-col :md="6" :sm="8" span="4">
            <span>收货人：{{ orderInfo.receiving_name }}<span v-if="orderInfo.receiving_phone">，{{ orderInfo.receiving_phone }}</span></span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span>地址：{{ orderInfo.province }}{{ orderInfo.city }}{{ orderInfo.county }}{{ orderInfo.address }}</span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span v-if="orderInfo.actual_paid">实付款：￥{{ (orderInfo.actual_paid / 100).toFixed(2) }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="16" style="margin-top: 10px;" v-if="false">
          <a-col :md="6" :sm="8" span="4">
            <span>订单状态：{{ this.$Dictionaries.applet_order_status[orderInfo.status] }}</span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span>配送方式：{{ this.$Dictionaries.delivery_method[orderInfo.distribution] }}</span>
          </a-col>
          <a-col :md="6" :sm="8" span="4">
            <span>创建时间：{{ orderInfo.create_time }}</span>
          </a-col>
        </a-row>
      </a-page-header>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :showPagination="false"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="price_render" slot-scope="text" style="min-width: 110px">
          {{ (text / 100).toFixed(2) }}
        </div>
        <span slot="img" slot-scope="img">
          <a-avatar
            v-if="img"
            slot="img"
            :src="img"
            shape="square"
            @click="openPreview(img)"
          />
        </span>
      </s-table>

      <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
        <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { orderDetail } from '@/api/counter_shop_order'

export default {
  name: 'TableList',
  components: {
    STable
  },
  props: {},
  data () {
    return {
      loading: false,
      confirmLoading: false,
      orderInfo: undefined,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 创建窗口控制
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      previewvisible: false,
      previewurl: '',
      // 表头
      columns: [
        {
          title: '序号',
          align: 'center',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '条形码',
          dataIndex: 'barcode'
        },
        {
          title: '商品名称',
          align: 'center',
          dataIndex: 'brief'
        },
        {
          title: '仓库对应名称',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '单价',
          align: 'center',
          dataIndex: 'price',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count'
        },
        {
          title: '总价',
          align: 'center',
          dataIndex: 'total_price',
          scopedSlots: { customRender: 'price_render' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return orderDetail(this.$route.query.order_no)
          .then((res) => {
            const localData = { entries: res.data }
            this.orderInfo = res.data
            return localData
          })
      }
    }
  },
  methods: {
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    }
  },
  created () {
    const order_no = this.$route.query.order_no
    console.log(order_no)
  }
}
</script>
